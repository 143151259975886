@font-face {
  font-family: Akzidenz;
  font-weight: medium;
  font-style: normal;
  src: url("/fonts/berthold-akzidenz-grotesk-medium.otf") format("opentype");
}

@font-face {
  font-family: Akzidenz;
  font-weight: bold;
  font-style: normal;
  src: url("/fonts/berthold-akzidenz-grotesk-bold.otf") format("opentype");
}

@font-face {
  font-family: Akzidenz;
  font-weight: normal;
  font-style: normal;
  src: url("/fonts/berthold-akzidenz-grotesk-regular.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
#root,
.App {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
input,
select {
  font-family: Akzidenz;
  font-size: 14pt;
}
